import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, first, firstValueFrom, Observable } from 'rxjs';
import { TokenStoreService } from './token-store.service';
import {
  AuthToken,
  BanShortResponse,
  Body,
  Body2,
  Client,
  LangResponse,
  LoginSmsRequest,
  Message,
  SendSmsRequest,
  SkillsRequest,
  UserDataResponse,
  UserResponse,
  VisitDataRequest,
} from '../api-clients/pyjam/client';
import { TranslateService } from '@ngx-translate/core';
import { ToastService } from '../services/toast.service';
import { DatePipe } from '@angular/common';
import { SignInWithAppleResponse } from '@capacitor-community/apple-sign-in';
import { User } from '@codetrix-studio/capacitor-google-auth';
import { jwtDecode, JwtPayload } from 'jwt-decode';
import { LoadingService } from '../services/loading.service';
import { OnlineStateService } from '../services/online-state.service';
import { SubscriptionsBag } from '../services/subscriptions-bag';
import { JwtService } from './jwt.service';
import { AppInfoService } from '../services/app-info.service';
import { PhoneInfoService } from '../avatar/services/phone-info.service';
import { WindowService } from '../services/window.service';
import { HttpStatusCode } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthService implements OnDestroy {
  public appleUser: SignInWithAppleResponse;
  public googleUser: User;
  // private readonly JWT_TOKEN: string = 'authToken';
  // private readonly USER_INFO: string = 'userInfo';
  private refreshTokenMutex: Promise<void> = null;
  private parsedTokenSubj: BehaviorSubject<ParsedToken | null> = new BehaviorSubject(null);
  private parsedUserSubj: BehaviorSubject<ParsedUser | null> = new BehaviorSubject(null);
  private sb: SubscriptionsBag = new SubscriptionsBag();

  ngOnDestroy() {
    this.sb.unsubscribeAll();
  }

  public get parsedToken$(): Observable<ParsedToken | null> {
    return this.parsedTokenSubj.asObservable();
  }

  public get parsedToken(): ParsedToken | null {
    return this.parsedTokenSubj.value;
  }

  public get parsedUser$(): Observable<ParsedUser | null> {
    return this.parsedUserSubj.asObservable();
  }

  public get parsedUser(): ParsedUser | null {
    return this.parsedUserSubj.value;
  }

  public get isAuthenticated(): boolean {
    return !!this.parsedToken;
  }

  public get isUserProfileIncomplete(): boolean {
    return this.parsedUser && this.parsedUser?.isUserNeedRegistration;
  }

  private set parsedToken(parsedToken: ParsedToken | null) {
    this.parsedTokenSubj.next(parsedToken);
  }

  private set parsedUser(parsedUser: ParsedUser | null) {
    this.parsedUserSubj.next(parsedUser);
  }

  constructor(
    private client: Client,
    private tokenStore: TokenStoreService,
    private translate: TranslateService,
    private datePipe: DatePipe,
    private toastService: ToastService,
    private loadingService: LoadingService,
    private onlineStateService: OnlineStateService,
    private jwtService: JwtService,
    private appInfoService: AppInfoService,
    private phoneInfoService: PhoneInfoService,
    private window: WindowService,
  ) {
  }

  public async init(): Promise<void> {
    if (this.onlineStateService.isOffline) {
      this.sb.sub = this.onlineStateService.isOffline$.pipe(
        first((isOffline: boolean): boolean => !isOffline),
      ).subscribe(async (): Promise<void> => {
        await this.init();
      });
      return;
    }

    try {
      const jwtToken: string = await this.tokenStore.getToken();
      this.parsedToken = ParsedToken.fromToken(jwtToken);

      await this.sendVisitData();

      if (!this.parsedToken) return;

      if (!this.parsedToken?.userId) {
        console.error('Invalid token!');
        this.parsedUser = null;
        return;
      }

      const userDataResponse: UserDataResponse = await firstValueFrom(this.client.userGet(this.parsedToken.userId));
      this.parsedUser = ParsedUser.fromResponse(userDataResponse?.data);
    } catch (error) {
      console.error(error);
    }
  }

  private async sendVisitData(): Promise<void> {
    const body: VisitDataRequest = new VisitDataRequest({
      device: await this.phoneInfoService.getDeviceModelWithOS(),
      app_version: await this.appInfoService.getShortAppVersion(),
    });

    if (!this.parsedToken) {
      body.temp_token = this.tokenStore.getTempToken();
      body.resolution = this.window.getScreenResolution();
    }
    await firstValueFrom(this.client.userVisitData(body));
  }

  public async loginWithToken(request: Body): Promise<void> {
    try {
      const authToken: AuthToken = await firstValueFrom(this.client.authLogin(request));

      if (authToken?.user?.role.includes('user') || authToken?.user?.role.includes('admin')) {
        await this.loginUserIfTokenAvailable(authToken);
      }
    } catch (error) {
      await this.authExecutionHandler(error);
    }
  }

  public async loginWithSmsCode(request: LoginSmsRequest): Promise<void> {
    try {
      await this.loadingService.start();

      const authToken: AuthToken = await firstValueFrom(this.client.authLoginSms(request));
      if (authToken?.user?.role.includes('user') || authToken?.user?.role.includes('admin')) {
        await this.loginUserIfTokenAvailable(authToken);
      } else {
        console.error('Invalid user role:', authToken?.user?.role);
      }
    } catch (error) {
      await this.authExecutionHandler(error);
      throw error;
    } finally {
      await this.loadingService.stop();
    }
  }

  public async sendSmsCodeRequest(request: SendSmsRequest): Promise<string> {
    try {
      const response: Message = await firstValueFrom(this.client.authSendSms(request));
      return response?.message;
    } catch (error) {
      if (error?.status === HttpStatusCode.TooManyRequests) {
        throw error;
      }

      console.error('Send SMS code request error:', error);
      await this.authExecutionHandler(error);
    }
  }

  private async authExecutionHandler(error: any): Promise<void> {
    const responseMessage: string = error?.result?.error ?? error?.result?.message;

    if (responseMessage === 'auth.user_not_found') {
      await this.toastService.error(this.translate.instant(responseMessage));
    } else if (responseMessage === 'auth.sms_service_not_available_for_your_mobile_operator') {
      await this.toastService.error(this.translate.instant(responseMessage));
    } else if (responseMessage === 'auth.invalid_code') {
      // processing in function smsCodeChanged
    } else {
      // await this.httpErrorHandlerService.handleHttpException(ex);
      const parsedException = JSON.parse(error?.response);
      const message = parsedException?.message ?? parsedException?.error;
      console.error('Login error:', error);
      await this.toastService.error(this.translate.instant(message));
    }
    throw error;
  }

  public async authMe(): Promise<UserDataResponse> {
    try {
      return await firstValueFrom(this.client.authMe());
    } catch (error) {
      console.error(error);
    }
  }

  public async refreshToken(): Promise<void> {
    if (this.refreshTokenMutex) {
      return this.refreshTokenMutex;
    }

    this.refreshTokenMutex = new Promise<void>(async (resolve, reject): Promise<void> => {
      const refreshToken: string = await this.tokenStore.getRefreshToken();

      if (!refreshToken) {
        console.error('No refresh token, logout...');
        this.refreshTokenMutex = null;
        await this.toastService.warning(this.translate.instant('auth.unauthorized'));
        await this.logout();
        return reject({message: 'No refresh token!'});
      }

      const isRefreshTokenExpired: boolean = this.jwtService.checkIsTokenExpired(refreshToken);

      if (isRefreshTokenExpired) {
        console.error('Refresh token expired, logout...');
        this.refreshTokenMutex = null;
        await this.toastService.warning(this.translate.instant('auth.unauthorized'));
        await this.logout();
        return reject({message: 'Refresh token expired!'});
      }

      try {
        const body: Body2 = new Body2({refresh_token: refreshToken});
        console.warn('Try to refresh token...');
        const authToken: AuthToken = await firstValueFrom(this.client.authRefresh(body));
        console.warn('Token refreshed successfully!');
        await this.loginUserIfTokenAvailable(authToken);
        resolve();
      } catch (error) {
        const responseMessage: string = error?.result?.error ?? error?.result?.message;

        if (responseMessage) {
          console.error('Token refresh error:', responseMessage);
          await this.toastService.error(responseMessage);
        } else {
          console.error('Token refresh error:', error);
        }
        reject(error);
      } finally {
        this.refreshTokenMutex = null;
      }
    });

    return this.refreshTokenMutex;
  }

  public async banFilter(type: number, callback: Function): Promise<void> {
    if (!this.isAuthenticated) {
      callback();
      return;
    }

    const userData: UserDataResponse = await this.authMe();
    const banShort: BanShortResponse = userData?.data?.bans.find((ban: BanShortResponse) => (ban?.type == type) && (new Date(ban?.date)).getTime() > (new Date()).getTime());

    if (!banShort) {
      callback();
    } else {
      const untilDate: string = this.datePipe.transform(banShort?.date, 'dd.MM.yyyy hh:mm');
      await this.toastService.error(this.translate.instant(
        (type == 2) ? 'ban.task' : 'ban.reply',
        {date: untilDate, reason: banShort?.reason})
      );
    }
  }

  private async loginUserIfTokenAvailable(authToken: AuthToken): Promise<void> {
    if (!authToken) {
      console.error('No auth token, logout...');
      await this.toastService.warning(this.translate.instant('auth.unauthorized'));
      return await this.logout();
    }

    await this.saveTokens(authToken);
  }

  public async logout(): Promise<void> {
    console.warn('Logout...');
    await this.clearTokens();
    if (typeof window !== 'undefined') {
      location.replace('/login'); // reload page
    }
  }

  private async saveTokens(authToken: AuthToken): Promise<void> {
    try {
      await this.tokenStore.setTokens(authToken?.access_token, authToken?.refresh_token);
      this.parsedToken = ParsedToken.fromToken(authToken?.access_token);
      this.parsedUser = ParsedUser.fromResponse(authToken?.user);
    } catch (error) {
      console.error(error);
    }
  }

  private async clearTokens(): Promise<void> {
    try {
      await this.tokenStore.removeTokens();
      this.parsedToken = null;
      this.parsedUser = null;
    } catch (error) {
      console.error(error);
    }
  }

  // private handleError(): Observable<never> {
  //   return throwError(this.translate.instant('notifications.badData'));
  // }

  // public getUserToken() {
  //   return JSON.parse(localStorage.getItem(this.JWT_TOKEN));
  // }

  // public getUserInfo() {
  //   return JSON.parse(localStorage.getItem(this.USER_INFO));
  // }

  //   public isAdmin() {
  //     const userInfo = this.getUserInfo();
  //     if (userInfo) {
  //       return userInfo?.role.includes('admin');
  //     }
  //     return false;
  //   }

  //   public isUser() {
  //     const userInfo = this.getUserInfo();
  //     if (userInfo) {
  //       return userInfo?.role.includes('user');
  //     }
  //     return false;
  //   }
}

export class ParsedToken {
  userId: number = null;
  expirationDate: Date = null;

  public static fromToken(jwtToken: string): ParsedToken {
    if (!jwtToken) return null;

    const jwtPayload: JwtPayload = jwtDecode(jwtToken);

    const newParsedToken: ParsedToken = new ParsedToken();
    newParsedToken.userId = Number(jwtPayload.sub);
    newParsedToken.expirationDate = new Date(jwtPayload.exp * 1000);

    if (!environment.production) console.info('\x1b[33m' + 'Token expiration date:' + '\x1b[0m', newParsedToken.expirationDate);

    return newParsedToken;
  }
}

export class ParsedUser {
  id: number = null;
  name: string = null;
  surname: string = null;
  isUserNeedRegistration: boolean = null;
  phone: string = null;
  email: string = null;
  avatarFile: any = null;
  description: string = null;
  rate: number = null;
  rating: number = null;
  skills: SkillsRequest[];
  lang: LangResponse;
  isOpenForProposals: boolean = null;
  replyOnResolveDispute: {
    hasReplyOnResolveDispute: boolean,
    taskId: number
  } = null;

  public static fromResponse(response: UserResponse): ParsedUser {
    if (!response) return null;

    const parsedUser: ParsedUser = new ParsedUser();

    parsedUser.id = response?.id;
    parsedUser.name = response?.name;
    parsedUser.surname = response?.surname;
    parsedUser.isUserNeedRegistration = !response?.name || !response?.surname;
    parsedUser.phone = response?.phone;
    parsedUser.email = response?.email;
    parsedUser.avatarFile = response?.avatar;
    parsedUser.description = response?.description;
    parsedUser.rate = response?.rate;
    parsedUser.rating = response?.rating;
    parsedUser.skills = response?.skills as SkillsRequest[];
    parsedUser.lang = response?.lang;
    parsedUser.isOpenForProposals = response?.is_open_for_proposals;
    parsedUser.replyOnResolveDispute = {
      hasReplyOnResolveDispute: response?.replyOnResolveDispute?.hasReplyOnResolveDispute,
      taskId: response?.replyOnResolveDispute?.taskId
    };

    return parsedUser;
  }
}
