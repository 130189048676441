import { Injectable } from '@angular/core';
import { UpdateAppModalComponent } from '../ui-components/update-app-modal/update-app-modal.component';
import { ModalController } from '@ionic/angular';
import { AppInfoService } from './app-info.service';
import { Client, VersionResponse } from '../api-clients/pyjam/client';
import { firstValueFrom } from 'rxjs';
import { URL_DOWNLOAD_APP } from '../app.constants';
import { PlatformService } from './platform.service';
import { LocalStorageService } from './local-storage.service';

@Injectable({
  providedIn: 'root'
})
export class UpdateAppService {
  private showPeriod: number = 14 * 24 * 60 * 60 * 1000; // 14 days

  constructor(
    private modalController: ModalController,
    private appInfoService: AppInfoService,
    private client: Client,
    private platformService: PlatformService,
    private localStorage: LocalStorageService
  ) {
  }

  public async checkActualAppVersion(): Promise<void> {
    try {
      const actualAppVersion: VersionResponse = await firstValueFrom(this.client.systemGetVersion());
      const currentAppVersion: string = await this.appInfoService.getShortAppVersion();

      if (this.compareVersions(actualAppVersion?.version, currentAppVersion)) {
        console.warn(`Application version ${currentAppVersion} is outdated!`);
        await this.openUpdateAppModal();
      }
    } catch (error) {
      console.error(error);
    }
  }

  public checkDownloadPromptPeriod(): void {
    if (this.platformService.isDevice || this.platformService.isSSR) return;

    const lastPrompt: string = this.localStorage.getItem('lastPrompt');
    const now: number = new Date().getTime();
    const periodExpired = !lastPrompt || now - Number(lastPrompt) > this.showPeriod;

    if (periodExpired) {
      this.showDownloadPrompt();
    }
  }

  public showDownloadPrompt(): void {
    if (typeof document === 'undefined') return;
    if (!this.platformService.isAndroidWeb && !this.platformService.isIosWeb) return;

    const downloadPromptEl: HTMLElement = document.getElementById('downloadPrompt');

    if (downloadPromptEl) {
      downloadPromptEl.style.display = 'block';
    }
  }

  public hideDownloadPrompt(): void {
    if (typeof document === 'undefined') return;

    const now: number = new Date().getTime();
    this.localStorage.setItem('lastPrompt', now.toString());
    const downloadPromptEl: HTMLElement = document.getElementById('downloadPrompt');

    if (downloadPromptEl) {
      downloadPromptEl.style.display = 'none';
    }
  }

  public async downloadApp(): Promise<void> {
    if (this.platformService.isAndroid) {
      document.location.href = URL_DOWNLOAD_APP.ANDROID;
    } else if (this.platformService.isIos) {
      document.location.href = URL_DOWNLOAD_APP.IOS;
    }
  }

  private compareVersions(actual: string, current: string): boolean {
    const actualParts: number[] = actual.split('.').map(Number);
    const currentParts: number[] = current.split('.').map(Number);

    const length: number = Math.max(actualParts.length, currentParts.length);

    for (let i = 0; i < length; i++) {
      const actual: number = actualParts[i] || 0;
      const current: number = currentParts[i] || 0;

      if (actual > current) return true;
    }
    return false;
  }

  private async openUpdateAppModal(): Promise<void> {
    const opts = {
      component: UpdateAppModalComponent,
    };
    const updateAppModal: HTMLIonModalElement = await this.modalController.create(opts);
    await updateAppModal?.present();
    const {role} = await updateAppModal?.onDidDismiss();

    if (role === 'download') {
      await this.downloadApp();
    }
  }
}
