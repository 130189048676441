import { AfterViewInit, Component, Input } from '@angular/core';
import { IonTextarea, ModalController } from '@ionic/angular';
import Swiper from 'swiper';
import { AddingFile } from '../image-adding/image-adding.component';

@Component({
  selector: 'app-add-description-to-image',
  templateUrl: './add-description-to-image.component.html',
  styleUrls: ['./add-description-to-image.component.scss'],
})
export class AddDescriptionToImageComponent implements AfterViewInit {
  @Input() public files: AddingFileWithDescription[] = [];
  // public imagePreviews = {};
  private swiper: Swiper;

  constructor(
    private modalController: ModalController,
    // private sanitizer: DomSanitizer,
  ) {
  }

  ngAfterViewInit() {
    if (this.files?.length) {
      this.initializeSwiper();
    }
  }

  private initializeSwiper(): void {
    this.swiper = new Swiper(
      '.description-swiper',
      {
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
          dynamicBullets: true,
        },
      }
    );
  }

  public async saveDescription(description: IonTextarea, file: AddingFileWithDescription): Promise<void> {
    file.description = description.value;
  }

  public async finishEditFilesInfo(): Promise<void> {
    await this.modalController?.dismiss({files: this.files});
  }

  // public sanitize(data: string): SafeUrl {
  //   return this.sanitizer.bypassSecurityTrustUrl(data);
  // }

  public async close(): Promise<void> {
    await this.modalController?.dismiss({});
  }
}

export class AddingFileWithDescription extends AddingFile {
  description: string;
}
