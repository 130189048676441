import { Injectable } from '@angular/core';
import { Body, CaptchaResource, Client, LoginSmsRequest, SendSmsRequest, } from '../api-clients/pyjam/client';
import { AuthService } from '../auth/auth.service';
import { AppInfoService } from '../services/app-info.service';
import { SessionStorageService } from '../services/session-storage.service';
import { TokenStoreService } from '../auth/token-store.service';
import { HttpStatusCode } from '@angular/common/http';
import { LoadingService } from '../services/loading.service';
import { Router } from '@angular/router';
import { firstValueFrom } from 'rxjs';
import { Animation, AnimationController, ModalController } from '@ionic/angular';
import { CaptchaModalComponent } from '../ui-components/captcha-modal/captcha-modal.component';

@Injectable()
export class LoginController {
  public login: string;
  public code: string;
  public isPhone: boolean = false;

  constructor(
    private authService: AuthService,
    private appInfoService: AppInfoService,
    private sessionStorage: SessionStorageService,
    private tokenStore: TokenStoreService,
    private loadingService: LoadingService,
    private animationController: AnimationController,
    private modalController: ModalController,
    private client: Client,
    private router: Router,
  ) {
  }

  public clear(): void {
    this.login = null;
    this.code = null;
  }

  public toggleIsPhone(isPhone: boolean): void {
    this.isPhone = isPhone;
  }

  public pushLogin(login: string): void {
    this.login = login;
  }

  public pushCode(code: string): void {
    this.code = code;
  }

  public async sendSmsCodeRequest(repeatRequest?: SendSmsRequest): Promise<string> {
    if (typeof window === 'undefined') return;

    let request: SendSmsRequest = repeatRequest;

    try {
      if (!repeatRequest) {
        request = new SendSmsRequest({
          version: await this.appInfoService.getLoginAppVersion(),
          login: this.login,
          temp_token: this.tokenStore.getTempToken(),
        });
      }
      return await this.authService.sendSmsCodeRequest(request);
    } catch (error) {
      await this.loadingService.stop();

      if (error?.status === HttpStatusCode.TooManyRequests) {
        console.error(error.message);
        await this.openCaptchaModal(request);
      }
      throw error;
    }
  }

  public async loginWithSmsCode(): Promise<void> {
    if (typeof window === 'undefined') return;

    const request = new LoginSmsRequest({
      version: await this.appInfoService.getLoginAppVersion(),
      login: this.login,
      code: this.code,
    });

    return this.authService.loginWithSmsCode(request);
  }

  public async loginWithToken(type: string, token: string): Promise<void> {
    if (typeof window === 'undefined') return;

    const request = new Body({
      version: await this.appInfoService.getLoginAppVersion(),
      type,
      token,
    });

    return this.authService.loginWithToken(request);
  }

  public async openCaptchaModal(request: SendSmsRequest): Promise<string> {
    try {
      const captchaData: CaptchaResource = await firstValueFrom(this.client.captcha());

      const modal: HTMLIonModalElement = await this.createCaptchaModal(captchaData.img);
      await modal?.present();
      const result = await modal.onWillDismiss();

      if (!result.data) return;

      const repeatRequest = new SendSmsRequest({
        ...request,
        captcha: result.data,
        key: captchaData.key,
      });

      await this.sendSmsCodeRequest(repeatRequest);
      await this.router.navigate(['/login/sign-in-confirm'], {skipLocationChange: true});
    } catch (error) {
      console.error(error);
    }
  }

  private async createCaptchaModal(base64: string): Promise<HTMLIonModalElement> {
    const enterAnimation = (baseEl: HTMLElement): Animation => {
      const root = baseEl.shadowRoot;

      const backdropAnimation = this.animationController
        .create()
        .addElement(root!.querySelector('ion-backdrop')!)
        .fromTo('opacity', '0.01', 'var(--backdrop-opacity)');

      const wrapperAnimation = this.animationController
        .create()
        .addElement(root!.querySelector('.modal-wrapper')!)
        .keyframes([
          {offset: 0, opacity: '0', transform: 'scale(0)'},
          {offset: 1, opacity: '0.99', transform: 'scale(1)'},
        ]);

      return this.animationController
        .create()
        .addElement(baseEl)
        .easing('ease-out')
        .duration(300)
        .addAnimation([backdropAnimation, wrapperAnimation]);
    };

    const leaveAnimation = (baseEl: HTMLElement): Animation => {
      return enterAnimation(baseEl).direction('reverse');
    };

    const opts = {
      component: CaptchaModalComponent,
      cssClass: 'captcha-modal',
      enterAnimation,
      leaveAnimation,
      componentProps: {
        captchaSrc: base64,
      }
    };
    return this.modalController.create(opts);
  }
}
