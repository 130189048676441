import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class WindowService {
  public innerWidth: number;
  public innerHeight: number;

  private readonly target: typeof window | null;

  constructor() {
    this.target = (typeof window !== 'undefined') ? window : null;

    return new Proxy(this, {
      get: (obj: this, prop: string | symbol, receiver: any): any => {
        if (typeof obj[prop] === 'function') return obj[prop];
        else if (typeof window === 'undefined') return null;

        if (prop in obj) {
          return Reflect.get(obj, prop, receiver);
        }

        return window[prop];
      },
      set: (obj: this, prop: string | symbol, value: any, receiver: any): boolean => {
        if (typeof window === 'undefined') return false;

        if (prop in obj) {
          return Reflect.set(obj, prop, value, receiver);
        }

        window[prop] = value;
        return true;
      },
      has: (obj: this, prop: string | symbol): boolean => {
        if (typeof window === 'undefined') return false;

        if (prop in obj) {
          return Reflect.has(obj, prop);
        }

        return (prop in window);
      }
    });
  }

  public getScreenResolution(): string {
    if (!this.target) return '0x0';

    return this.target.screen.availWidth + 'x' + this.target.screen.availHeight;
  }
}