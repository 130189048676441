import { Injectable } from '@angular/core';
import { BatteryInfo, Device, DeviceInfo } from '@capacitor/device';
import { BehaviorSubject, from, Observable, Subscription, timer } from 'rxjs';
import { STATE, UPDATE_INTERVAL } from '../avatar.constants';
import { switchMap } from 'rxjs/operators';
import { PlatformService } from '../../services/platform.service';
import { ToastService } from '../../services/toast.service';
import { TranslateService } from '@ngx-translate/core';
import { CustomBatteryInfo } from '../gmap/gmap.interfaces';

declare var navigator: Navigator;

interface BatteryManager extends EventTarget {
  readonly charging: boolean;
  readonly chargingTime: number;
  readonly dischargingTime: number;
  readonly level: number;
  onchargingchange: ((this: BatteryManager, ev: Event) => any) | null;
  onchargingtimechange: ((this: BatteryManager, ev: Event) => any) | null;
  ondischargingtimechange: ((this: BatteryManager, ev: Event) => any) | null;
  onlevelchange: ((this: BatteryManager, ev: Event) => any) | null;
}

interface Navigator extends globalThis.Navigator {
  getBattery?: () => Promise<any>;
}

@Injectable({
  providedIn: 'root'
})
export class PhoneInfoService {
  public deviceModel: string | null = null;
  public osVersion: string | null = null;
  public isWatchBattery: boolean = false;
  private initBattery: CustomBatteryInfo = {level: -1, isCharging: false};
  public myBatterySubject: BehaviorSubject<CustomBatteryInfo> = new BehaviorSubject(this.initBattery);
  private executorBatterySubject: BehaviorSubject<CustomBatteryInfo> = new BehaviorSubject(this.initBattery);
  public executorBattery$: Observable<CustomBatteryInfo> = this.executorBatterySubject.asObservable();
  private batteryStatusSubscription: Subscription | null = null;
  private batteryManager: BatteryManager | null = null;

  constructor(
    public platformService: PlatformService,
    private toastService: ToastService,
    private translate: TranslateService,
  ) {
  }

  ngOnDestroy() {
    if (this.batteryStatusSubscription) {
      this.batteryStatusSubscription.unsubscribe();
      this.batteryStatusSubscription = null;
    }

    if (this.batteryManager) {
      this.batteryManager.onlevelchange = null;
      this.batteryManager.onchargingchange = null;
      this.batteryManager = null;
    }
  }

  public async getDeviceModelWithOS(): Promise<string> {
    if (!this.deviceModel && !this.osVersion) {
      await this.getDeviceInfo();
    }
    return `${this.deviceModel} (${this.osVersion})`;
  }

  public async getDeviceInfo(): Promise<void> {
    if (this.deviceModel && this.osVersion) return;

    try {
      const info: DeviceInfo = (typeof navigator !== 'undefined') ? await Device.getInfo() : null;
      this.deviceModel = (info) ? this.getShortModel(info.manufacturer, info.model) : '';
      this.osVersion = (info) ? this.getShortOSVersion(info.operatingSystem, info.osVersion) : '';
    } catch (error) {
      console.error(error);
    }
  }

  public async toggleBatteryChargeWatching(state: STATE): Promise<void> {
    if (this.platformService.isDevice) {
      this.toggleBatteryChargeWatchingWithDevice(state);
    } else {
      await this.toggleBatteryChargeWatchingWithBrowser(state);
    }
  }

  public updateExecutorBatteryInfo(level: number, isCharging: boolean): void {
    this.executorBatterySubject.next({level, isCharging});
  }

  private getShortModel(manufacturer: string, model: string): string {
    const shortModel: string = model.split(' ').slice(0, 2).join(' ');
    return `${manufacturer} ${shortModel ? shortModel : 'Unknown'}`;
  }

  private getShortOSVersion(osName: string, osVersion: string): string {
    const shortVersion: RegExpMatchArray = osVersion.match(/(\d+(\.\d+)?)/);
    const capitalizedOS: string = this.capitalizeFirstLetter(osName);
    return `${capitalizedOS} ${shortVersion ? shortVersion[0] : 'Unknown'}`;
  }

  private capitalizeFirstLetter(string: string): string {
    if (string === 'ios') {
      return 'iOS';
    } else {
      return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
    }
  }

  private async getBatteryInfo(): Promise<void> {
    try {
      const status: BatteryInfo = await Device.getBatteryInfo();
      this.updateMyBatteryInfo(Math.round(status.batteryLevel * 100), status.isCharging);
    } catch (error) {
      console.error(error);
      await this.toastService.error(this.translate.instant('avatar.avatarService.toasts.battery.errorWatchingBattery'));
    }
  }

  private toggleBatteryChargeWatchingWithDevice(state: STATE): void {
    switch (state) {
      case STATE.ACTIVATE:
        if (this.batteryStatusSubscription) return;

        this.batteryStatusSubscription = timer(0, UPDATE_INTERVAL.BATTERY).pipe(
          switchMap((): Observable<void> => from(this.getBatteryInfo()))
        ).subscribe();

        this.isWatchBattery = true;

        break;
      case STATE.DEACTIVATE:
        if (!this.batteryStatusSubscription) return;

        if (this.batteryStatusSubscription) {
          this.isWatchBattery = false;
          this.batteryStatusSubscription.unsubscribe();
          this.batteryStatusSubscription = null;
        }

        break;
    }
  }

  private async toggleBatteryChargeWatchingWithBrowser(state: STATE): Promise<void> {
    switch (state) {
      case STATE.ACTIVATE:
        if (this.batteryManager) return;

        if ((typeof window !== 'undefined') && navigator.getBattery) {
          try {
            this.batteryManager = await navigator.getBattery();
            this.updateMyBatteryInfo(Math.round(this.batteryManager.level * 100), this.batteryManager.charging);

            this.batteryManager.onlevelchange = (): void => {
              this.updateMyBatteryInfo(Math.round(this.batteryManager.level * 100), this.batteryManager.charging);
            };

            this.batteryManager.onchargingchange = (): void => {
              this.updateMyBatteryInfo(Math.round(this.batteryManager.level * 100), this.batteryManager.charging);
            };

            this.isWatchBattery = true;
          } catch (error) {
            await this.toastService.error(this.translate.instant('avatar.avatarService.toasts.battery.errorWatchingBattery'));
            console.error(error);
          }
        } else {
          console.log('Battery Status API is not supported on this device.');
        }

        break;
      case STATE.DEACTIVATE:
        if (!this.batteryManager) return;

        if (this.batteryManager) {
          this.batteryManager.onlevelchange = null;
          this.batteryManager.onchargingchange = null;
          this.batteryManager = null;
          this.isWatchBattery = false;
        }

        break;
    }
  }

  private updateMyBatteryInfo(level: number, isCharging: boolean): void {
    this.myBatterySubject.next({level, isCharging});
  }
}
