import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InitialsPipe } from './initials.pipe';
import { SizeFilePipe } from './size-file.pipe';
import { IconForFilePipe } from './icon-for-file.pipe';
import { LinkInTextPipe } from './link-in-text.pipe';
import { PriceLocalePipe } from './priceLocale.pipe';
import { TimeAgoPipe } from './time-ago.pipe';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [
    InitialsPipe,
    SizeFilePipe,
    IconForFilePipe,
    LinkInTextPipe,
    PriceLocalePipe,
    TimeAgoPipe,
  ],
  imports: [
    CommonModule,
    TranslateModule,
  ],
  exports: [
    InitialsPipe,
    SizeFilePipe,
    IconForFilePipe,
    LinkInTextPipe,
    PriceLocalePipe,
    TimeAgoPipe,
  ]
})
export class PipesModule {
}
