import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpStatusCode, } from '@angular/common/http';
import { first, from, lastValueFrom, Observable } from 'rxjs';
import { TokenStoreService } from './token-store.service';
import { AuthService } from './auth.service';
import { NavController } from '@ionic/angular';
import { environment } from '../../environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { ToastService } from '../services/toast.service';
import { JwtService } from './jwt.service';
import { OnlineStateService } from '../services/online-state.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(
    private navController: NavController,
    private tokenStore: TokenStoreService,
    private authService: AuthService,
    private translate: TranslateService,
    private toastService: ToastService,
    private jwtService: JwtService,
    private onlineStateService: OnlineStateService,
  ) {
  }

  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return from(this.interceptInternal(request, next));
  }

  private async interceptInternal(request: HttpRequest<any>, next: HttpHandler): Promise<HttpEvent<any>> {
    // Проверяем наличие интернета, если его нет, то ждём пока он появится
    if (this.onlineStateService.isOffline) {
      // К сожалению, фронт не всегда успевает узнать о состоянии интернета, например когда приложение свёрнуто, поэтому некоторые запросы могут упасть
      console.warn('No internet connection, request will be delayed until online');
      await this.delayUntilOnline();
    }

    if (!request.url.includes(environment.urlBackEndApi)
      || request.url.includes('/auth/refresh')) {
      return lastValueFrom(next.handle(request));
    }

    const jwtToken: string = await this.tokenStore.getToken();
    const isTokenExpired: boolean = this.jwtService.checkIsTokenExpired(jwtToken);

    // Предварительный рефреш(по расчёту срока действия токена на фронте на основе времени устройства + 10 сек)
    if (jwtToken && isTokenExpired) {
      console.warn('interceptor: Token expired!');

      try {
        await this.authService.refreshToken(); // Здесь может разлогинить если рефреш токен не валиден
      } catch (error) {
        if (error?.status === HttpStatusCode.Unauthorized) {
          console.error('First refresh token failed, logout...');
          const responseMessage: string = error?.result?.error ?? error?.result?.message;
          await this.logout(responseMessage);
          await this.handleHttpException(error);
          throw error;
        }
      }
    }

    // Основной запрос с токеном(если он есть)
    try {
      const patchedRequest: HttpRequest<any> = await this.patchRequestIfTokenAvailable(request);
      return await lastValueFrom(next.handle(patchedRequest));
    } catch (error) {
      if (error?.status !== HttpStatusCode.Unauthorized) {
        await this.handleHttpException(error);
        throw error;
      }
      // Если HttpStatusCode.Unauthorized, то рефрешим токен и повторяем запрос
    }

    // Рефреш по ответу бэка об отсутствии авторизации(фронт мог посчитать токен валидным, но бэк нет)
    try {
      await this.authService.refreshToken(); // Здесь может разлогинить если рефреш токен не валиден
    } catch (error) {
      if (error?.status === HttpStatusCode.Unauthorized) {
        console.error('Retry refresh token failed, logout...');
        const responseMessage: string = error?.result?.error ?? error?.result?.message;
        await this.logout(responseMessage);
      }
      await this.handleHttpException(error);
      throw error;
    }

    // Повторный запрос после повторного рефреша
    try {
      const patchedRequest: HttpRequest<any> = await this.patchRequestIfTokenAvailable(request);
      return await lastValueFrom(next.handle(patchedRequest));
    } catch (error) {
      if (error?.status === HttpStatusCode.Unauthorized) {
        console.error('Retry request failed, logout...');
        const responseMessage: string = error?.result?.error ?? error?.result?.message;
        await this.logout(responseMessage);
      }
      await this.handleHttpException(error);
      throw error;
    }
  }

  private async delayUntilOnline(): Promise<void> {
    await lastValueFrom(
      this.onlineStateService.isOffline$.pipe(
        first((isOffline: boolean): boolean => !isOffline)
      )
    );
  }

  private async patchRequestIfTokenAvailable(request: HttpRequest<any>): Promise<HttpRequest<any>> {
    const jwtToken: string = await this.tokenStore.getToken();

    if (jwtToken) {
      return request.clone({headers: request.headers.set('Authorization', `Bearer ${jwtToken}`)});
    } else {
      const tempToken: string = this.tokenStore.getTempToken();
      return request.clone({headers: request.headers.set('baggage', tempToken)});
    }
  }

  private async handleHttpException(error: any): Promise<void> {
    const responseMessage: string = error?.result?.error ?? error?.result?.message;

    if (responseMessage) {
      const codesForRedirect: string[] = ['ban.task', 'ban.reply', 'ban.forever'];

      if (error.status == HttpStatusCode.Forbidden && codesForRedirect.includes(responseMessage)) {
        await this.toastService.error(this.translate.instant('errors.' + responseMessage)); // 'ban.task', 'ban.reply', 'ban.forever'
        await this.navController.navigateBack(['/avatar']);
      } else if (!environment.production) {
        await this.toastService.error(responseMessage);
      }
    } else {
      console.error(error.message);
    }
  }

  private async logout(message: string): Promise<void> {
    const translate: string = this.translate.instant(message);
    console.error(translate);
    await this.toastService.warning(translate);
    await this.authService.logout();
  }
}
