import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { authCanActivate } from './auth/auth.guard';
import { disputeCanActivate } from './task/dispute/dispute.guard';
import { mobileAppCanActivate } from './pyjam-animate/mobile-app.guard';
import { NotFoundComponent } from './404/not-found.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'pyjam-animate',
    pathMatch: 'full'
  },
  {
    path: 'pyjam-animate',
    loadComponent: () => import('./pyjam-animate/pyjam-animate.component').then(c => c.PyjamAnimateComponent),
    canActivate: [mobileAppCanActivate],
  },
  {
    path: 'login',
    loadChildren: () => import('./login/login.module').then(m => m.LoginModule)
  },
  {
    path: 'registration',
    loadChildren: () => import('./registration/registration.module').then(m => m.RegistrationModule),
    canActivate: [authCanActivate, disputeCanActivate],
  },
  {
    path: 'tasks',
    loadChildren: () => import('./task/task.module').then(m => m.TaskModule),
  },
  {
    path: 'people',
    loadChildren: () => import('./people/people.module').then(m => m.PeopleModule),
  },
  {
    path: 'notifications',
    loadComponent: () => import('./notification/notifications.component').then(c => c.NotificationsComponent),
    canActivate: [disputeCanActivate],
    data: {showMenu: true, title: 'seo.title.notifications', description: 'seo.description.notifications'},
  },
  {
    path: 'chats',
    loadChildren: () => import('./chat/chat.module').then(m => m.ChatModule),
    canActivate: [disputeCanActivate],
  },
  {
    path: 'profile',
    loadChildren: () => import('./profile/profile.module').then(m => m.ProfileModule),
    canActivate: [disputeCanActivate],
  },
  {
    path: 'avatar',
    loadChildren: () => import('./avatar/avatar.module').then(m => m.AvatarModule),
  },
  {
    path: 'reply',
    loadChildren: () => import('./reply/reply.module').then(m => m.ReplyModule),
    canActivate: [authCanActivate, disputeCanActivate],
  },
  {
    path: 'credit-info',
    loadChildren: () => import('./stripe/stripe.module').then(m => m.StripeModule),
  },
  {
    path: 'report',
    loadChildren: () => import('./report/report.module').then(m => m.ReportModule),
    canActivate: [authCanActivate, disputeCanActivate],
  },
  {
    path: 'support',
    loadComponent: () => import('./support/support.component').then(c => c.SupportComponent),
    data: {showMenu: false}
  },
  {
    path: 'license-agreement-en.html',
    loadComponent: () => import('./docs/license-agreement/license-agreement.component').then(c => c.LicenseAgreementComponent),
    data: {showMenu: false}
  },
  {
    path: 'privacy-policy-en.html',
    loadComponent: () => import('./docs/privacy-policy/privacy-policy.component').then(c => c.PrivacyPolicyComponent),
    data: {showMenu: false}
  },
  {
    path: 'cookies-policy-en.html',
    loadComponent: () => import('./docs/cookies-policy/cookies-policy.component').then(c => c.CookiesPolicyComponent),
    data: {showMenu: false}
  },
  {
    path: 'terms-and-conditions-en.html',
    loadComponent: () => import('./docs/terms-and-conditions/terms-and-conditions.component').then(c => c.TermsAndConditionsComponent),
    data: {showMenu: false}
  },
  {
    path: 'disclaimer-en.html',
    loadComponent: () => import('./docs/disclaimer/disclaimer.component').then(c => c.DisclaimerComponent),
    data: {showMenu: false}
  },
  {
    path: 'extra-work',
    loadChildren: () => import('./extra-work/extra-work.module').then(m => m.ExtraWorkModule),
    canActivate: [authCanActivate],
  },
  {
    path: '404',
    component: NotFoundComponent,
    data: {showMenu: true, title: 'seo.title.pageNotFound', description: 'seo.description.pageNotFound'}
  },
  {
    path: '**',
    component: NotFoundComponent,
    data: {showMenu: true, title: 'seo.title.pageNotFound', description: 'seo.description.pageNotFound'}
    //redirectTo: 'avatar'
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {preloadingStrategy: PreloadAllModules, initialNavigation: 'enabledBlocking'})
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
