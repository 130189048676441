import { Injectable } from '@angular/core';
import { AlertController, AlertOptions, LoadingController, LoadingOptions } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class LoadingService {
  private countOfLoaders: number = 0;
  private timeoutAllowAbort: number = 10_000; // 10 seconds
  private loadingElement: HTMLIonLoadingElement;
  private timerLoadingAbort: NodeJS.Timeout | null = null;
  private alertElement: HTMLIonAlertElement;

  constructor(
    private loadingController: LoadingController,
    private translate: TranslateService,
    private alertController: AlertController,
  ) {
  }

  public async start(): Promise<void> {
    if (typeof window === 'undefined') return;

    this.countOfLoaders++;

    if (this.countOfLoaders == 1 && !this.loadingElement) {
      await this.showLoaderWithTimer();
    }
  }

  public async stop(): Promise<void> {
    if (typeof window === 'undefined') return;

    this.countOfLoaders--;

    if (this.countOfLoaders == 0) {
      if (this.loadingElement) {
        await this.deleteLoadingElement();
      }
      if (this.alertElement) {
        await this.deleteAlertElement();
      }
    }
  }

  private createLoaderElement(): Promise<HTMLIonLoadingElement> {
    const options: LoadingOptions = {
      message: this.translate.instant('loading'), // FIXME: error: "Translation missing for key:  loading"
      backdropDismiss: false,
      cssClass: 'loader-transparent',
    };

    return this.loadingController.create(options);
  }

  private addAbortListeners(): void {
    if (!this.loadingElement) return;

    this.loadingElement.backdropDismiss = true;

    this.loadingElement.addEventListener('ionBackdropTap', async (): Promise<void> => {
      await this.showAlertElement();
    });

    this.loadingElement.addEventListener('click', async (): Promise<void> => {
      await this.showAlertElement();
    });
  }

  private async showLoader(): Promise<void> {
    if (this.loadingElement) return;

    this.loadingElement = await this.createLoaderElement();
    await this.loadingElement?.present();
  }

  private async showLoaderWithTimer(): Promise<void> {
    await this.showLoader();
    this.timerLoadingAbort = setTimeout(() => this.addAbortListeners(), this.timeoutAllowAbort);
  }

  private async showLoaderWithoutTimer(): Promise<void> {
    await this.showLoader();
    this.addAbortListeners();
  }

  private async deleteLoadingElement(): Promise<void> {
    clearTimeout(this.timerLoadingAbort);

    if (!this.loadingElement) return;

    await this.loadingController?.dismiss();
    this.loadingElement = null;
  }

  private createAlertElement(): Promise<HTMLIonAlertElement> {
    const opts: AlertOptions = {
      header: this.translate.instant('loadingService.timeoutRequest.alert.header'),
      message: this.translate.instant('loadingService.timeoutRequest.alert.message'),
      cssClass: 'alert-loader-abort',
      backdropDismiss: false,
      buttons: [
        {
          text: this.translate.instant('loadingService.timeoutRequest.alert.buttons.cancel'),
          role: 'cancel',
          handler: async () => {
            await this.showLoaderWithoutTimer();
          }
        },
        {
          text: this.translate.instant('loadingService.timeoutRequest.alert.buttons.destructive'),
          role: 'destructive',
          handler: async () => {
            await this.deleteAlertElement();
          }
        }
      ]
    };

    return this.alertController.create(opts);
  }

  private async showAlertElement(): Promise<void> {
    if (!this.loadingElement) return;

    this.alertElement = await this.createAlertElement();
    await this.deleteLoadingElement();
    await this.alertElement?.present();
  }

  private async deleteAlertElement(): Promise<void> {
    if (!this.alertElement) return;

    await this.alertElement?.dismiss();
    this.alertElement = null;
  }
}
