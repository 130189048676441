import { Component, Input } from '@angular/core';
import { Params } from '@angular/router';
import { BackButtonService } from '../../services/back-button.service';
import { SessionStorageService } from '../../services/session-storage.service';

@Component({
  selector: 'app-back-button',
  templateUrl: './back-button.component.html',
  styleUrls: ['./back-button.component.scss'],
})
export class BackButtonComponent {
  @Input() defaultHref: string | undefined = undefined;
  @Input() queryParams: Params | undefined = undefined;
  @Input() disableClick: boolean = false;
  private readonly lastURL: string | undefined = undefined;

  public constructor(
    // @Optional() private routerOutlet: IonRouterOutlet,
    private backButtonService: BackButtonService,
    private sessionStorage: SessionStorageService,
  ) {
    this.lastURL = this.sessionStorage.getItem('lastURL');
  }

  public onClick(): void {
    if (this.disableClick) return;

    this.backButtonService.goBack(this.defaultHref || this.lastURL || '/avatar', this.queryParams);
  }
}
