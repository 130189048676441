import { Component, OnInit, ViewChild } from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator
} from '@angular/forms';
import { Client, CountryPhoneCodes, OnboardingCountyCodes } from '../../../api-clients/pyjam/client';
import { IonInput, IonModal } from '@ionic/angular';
import { LoadingService } from '../../../services/loading.service';
import { firstValueFrom } from 'rxjs';

@Component({
  selector: 'app-phone-input',
  templateUrl: './phone-input.component.html',
  styleUrls: ['./phone-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: PhoneInputComponent
    },
    {
      provide: NG_VALIDATORS,
      multi: true,
      useExisting: PhoneInputComponent
    }
  ]
})
export class PhoneInputComponent implements ControlValueAccessor, Validator, OnInit {
  @ViewChild(IonModal) codesModal: IonModal;
  @ViewChild(IonInput) phoneInput: IonInput;
  public phoneControl: PhoneInputControlValue = new PhoneInputControlValue();
  public filteredCountyCodes: OnboardingCountyCodes[] = [];
  private countyCodes: OnboardingCountyCodes[] = [];
  private touched: boolean = false;

  constructor(
    private loadingService: LoadingService,
    private client: Client,
  ) {
  }

  async ngOnInit(): Promise<void> {
    await this.loadCountryCodes();
  }

  public onChange = (phone): void => {
    //
  };

  public onTouched = (): void => {
    this.touched = true;
  };

  public validate(control: AbstractControl): ValidationErrors | null {
    const phoneInput = control.value as PhoneInputControlValue;

    return (!phoneInput.isPhoneValid)
      ? {phoneLength: phoneInput.code?.phone_length}
      : null;
  }

  public registerOnChange(onChange: any): void {
    this.onChange = onChange;
  }

  public registerOnTouched(onTouched: any): void {
    this.onTouched = onTouched;
  }

  public writeValue(phone: any): void {
    this.phoneControl.phone = phone;
  }

  public setDisabledState(isDisabled: boolean): void {
    // this.phoneInput.disabled = isDisabled;
  }

  public async phoneCodeChanged(event: any): Promise<void> {
    this.phoneControl.code = event.detail.value;
    this.phoneInput.value = '';
    await this.codesModal?.dismiss();
    this.filteredCountyCodes = this.countyCodes;
  }

  public async onInputKeyDown(event: any): Promise<void> {
    if (event.key == 'ArrowRight' || event.key == 'ArrowDown'
      || event.key == 'ArrowLeft' || event.key == 'ArrowUp'
      || event.key == 'Delete' || event.key == 'Backspace'
      || event.key == '0' || event.key == '1'
      || event.key == '2' || event.key == '3'
      || event.key == '4' || event.key == '5'
      || event.key == '6' || event.key == '7'
      || event.key == '8' || event.key == '9') {
    } else {
      event.preventDefault();
    }
  }

  public async onInputPaste(event: any): Promise<void> {
    let clipboardData: any = event.clipboardData;
    let pastedText: string = clipboardData.getData('text');
    this.phoneControl.phone = pastedText.replace(/[^\d]/g, '');
    event.preventDefault();
  }

  public onInputFocus(): void {
    this.markAsTouched();
  }

  public onInputValueChange(event: any): void {
    this.phoneControl.phone = event.detail.value;
    this.onChange(this.phoneControl);
  }

  public onSearchInput(event: any): void {
    const query: string = event.target.value.toLowerCase();
    this.filteredCountyCodes = this.countyCodes.filter((code: OnboardingCountyCodes): boolean =>
      code.name.toLowerCase().includes(query) || code.phone_code.includes(query)
    );
  }

  private async loadCountryCodes(): Promise<void> {
    try {
      await this.loadingService.start();

      this.countyCodes = await firstValueFrom(this.client.authGetCountryPhoneCodes());
      this.filteredCountyCodes = this.countyCodes;
      this.phoneControl.code = this.countyCodes.find((code: CountryPhoneCodes): boolean => code.default);
    } catch (error) {
      console.error(error);
    } finally {
      await this.loadingService.stop();
    }
  }

  private markAsTouched(): void {
    if (this.touched) return;

    this.onTouched();
  }
}

export class PhoneInputControlValue {
  public code: OnboardingCountyCodes;
  public phone: string;

  public get isPhoneValid(): boolean {
    return (this.code)
      ? new RegExp('^[0-9]{' + this.code.min_digits + ',' + this.code.max_digits + '}$').test(this.phone)
      : false;
  }

  public toString() {
    return (this.code && this.phone)
      ? `${this.code.phone_code}${this.phone}`
      : '';
  }
}
