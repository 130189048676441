import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { ActionSheetController, ActionSheetOptions, IonModal, ModalController, ModalOptions } from '@ionic/angular';
import { ExtendedClient, FileTypes } from '../../api-clients/pyjam/extended-client';
import { FileResponse } from '../../api-clients/pyjam/client';
import { Browser } from '@capacitor/browser';
import Swiper, { Pagination, Zoom } from 'swiper';
import { TranslateService } from '@ngx-translate/core';
import {
  AddDescriptionToImageComponent,
  AddingFileWithDescription
} from '../add-description-to-image/add-description-to-image.component';
import { ToastService } from '../../services/toast.service';
import { HttpErrorHandlerService } from '../../services/error-handlers/http-error-handler.service';
import { LoadingService } from '../../services/loading.service';
import { ActivatedRoute, Router } from '@angular/router';
import { firstValueFrom, fromEvent, Subscription } from 'rxjs';
import { BackButtonService } from '../../services/back-button.service';
import { ReportService } from '../../services/report.service';
import { ReportType } from '../../report/report.constants';
import { ParsedUser } from '../../auth/auth.service';
import { ActionSheetButton } from '@ionic/core/dist/types/components/action-sheet/action-sheet-interface';
import { Location } from '@angular/common';

Swiper.use([Pagination, Zoom]);

@Component({
  selector: 'app-image-slider',
  templateUrl: './image-slider.component.html',
  styleUrls: ['./image-slider.component.scss'],
})
export class ImageSliderComponent implements OnInit, OnDestroy {
  @ViewChild('imageSliderModal') private imageSliderModal: IonModal;
  @Input() public allowDownload: boolean = true;
  @Input() public allowEditing: boolean = false;
  @Input() public allowDeleting: boolean = false;

  @Input() public set selectedFilesForSlider(value: FileResponse[]) {
    const selectedFilesLengthBefore: number = this.selectedFiles.length;
    this.selectedFiles = value;

    if (this.selectedFiles.length) {
      const hasEmbeddedFiles: boolean = this.selectedFiles.some((file: FileResponse): boolean => file.path.startsWith('data:'));
      this.allowDownload = !hasEmbeddedFiles;
    } else {
      if (selectedFilesLengthBefore > 0 && (typeof window !== 'undefined') && location.search.includes('showImage')) {
        this.backButtonService.goBack(null);
      }
    }
  };

  @Output() public deleteFileEvent: EventEmitter<number> = new EventEmitter();
  public readonly FileTypes = FileTypes;
  public parsedUser: ParsedUser | null = null;
  private swiper: Swiper | null = null;
  private selectedFiles: FileResponse[] = [];
  private menuButtons: (ActionSheetButton | string)[] = [];
  private popstateSubscription: Subscription | null = null;

  public get selectedFilesForSlider(): FileResponse[] {
    return this.selectedFiles;
  };

  constructor(
    private actionSheetController: ActionSheetController,
    private translate: TranslateService,
    private toastService: ToastService,
    private extendedClient: ExtendedClient,
    private httpErrorHandlerService: HttpErrorHandlerService,
    private modalController: ModalController,
    private loadingService: LoadingService,
    private location: Location,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private backButtonService: BackButtonService,
    private reportService: ReportService,
  ) {
  }

  ngOnInit() {
    this.createMenuButtons();
  }

  ngOnDestroy() {
    this.unsubscribeToPopstate();
  }

  public async showImages(files: FileResponse[], index: number, parsedUser?: ParsedUser): Promise<void> {
    const initialSlide: number = index | 0;
    const filesForSlider: FileResponse[] = [];

    for (let i = initialSlide; i < files.length; i++) {
      filesForSlider.push(files[i]);
    }

    if (initialSlide > 0) {
      for (let i = 0; i < initialSlide; i++) {
        filesForSlider.push(files[i]);
      }
    }

    this.selectedFilesForSlider = filesForSlider;

    if (parsedUser) {
      this.parsedUser = parsedUser;
    }

    await this.openModal();
  }

  public async downloadFile(): Promise<void> {
    const file: FileResponse = this.selectedFilesForSlider[this.swiper.activeIndex];
    await Browser.open({url: file?.origin?.url ? file?.origin?.url : file.url});
  }

  public async openMenu(): Promise<void> {
    const opts: ActionSheetOptions = {
      buttons: this.menuButtons,
    };
    const actionSheet: HTMLIonActionSheetElement = await this.actionSheetController.create(opts);
    await actionSheet?.present();
  }

  public async closeModal(popstate: boolean = false): Promise<void> {
    await this.imageSliderModal?.dismiss();
    if (!popstate) this.location.back();
    this.unsubscribeToPopstate();
  }

  private async openModal(): Promise<void> {
    await this.imageSliderModal?.present();

    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: {showImage: true},
      queryParamsHandling: 'merge',
    }).then(() => {
      this.subscribeToPopstate();
    });

    this.initializeSwiper();
  }

  private createMenuButtons(): void {
    this.translate.get('userProfile.buttons.edit').subscribe(() => {
      if (this.allowEditing) {
        this.menuButtons.push(
          {
            text: this.translate.instant('userProfile.buttons.edit'),
            handler: async () => {
              const filesForSent: AddingFileWithDescription[] = [];
              const newFile = JSON.parse(JSON.stringify(this.selectedFilesForSlider[this.swiper.activeIndex]));
              newFile.type = newFile.type.name;
              filesForSent.push({
                id: newFile.id,
                mimeType: newFile.type,
                base64: '',
                src: newFile.resizes?.length ? newFile.resizes[0].url : newFile.url,
                size: newFile.size,
                name: newFile.name,
                description: newFile.description
              } as AddingFileWithDescription);

              const opts: ModalOptions = {
                component: AddDescriptionToImageComponent,
                componentProps: {
                  files: filesForSent,
                },
                backdropDismiss: false,
                keyboardClose: false,
              };
              const modal: HTMLIonModalElement = await this.modalController.create(opts);
              await modal?.present();

              const eventDetail = await modal?.onDidDismiss();

              if (eventDetail && eventDetail.data && eventDetail.data.files && eventDetail.data.files.length) {
                try {
                  await this.loadingService.start();

                  await firstValueFrom(this.extendedClient.profileFileDescriptionChange(eventDetail.data.files[0]));
                  await this.toastService.success(this.translate.instant('userProfile.fileChanged'));
                  this.selectedFilesForSlider[this.swiper.activeIndex].description = eventDetail.data.files[0].description;
                } catch (error) {
                  await this.httpErrorHandlerService.handleHttpException(error);
                } finally {
                  await this.loadingService.stop();
                }
              }
            }
          },
        );
      }

      if (this.allowDeleting) {
        this.menuButtons.push({
            role: 'destructive',
            text: this.translate.instant('userProfile.buttons.remove'),
            handler: () => {
              this.deleteFileEvent.emit(this.selectedFilesForSlider[this.swiper.activeIndex].id);
            }
          },
        );
      }

      if (!this.allowEditing && !this.allowDeleting) {
        this.menuButtons.push(
          {
            text: this.translate.instant('report.type.' + ReportType.FILE),
            handler: () => {
              this.reportService.openReportModal(ReportType.FILE, this.selectedFilesForSlider[this.swiper.activeIndex].id).then();
            }
          }
        );
      }
    });
  }

  private initializeSwiper(): void {
    this.swiper = new Swiper(
      '.image-wiper',
      {
        loop: false,
        zoom: {
          maxRatio: 3
        },
        initialSlide: 0,
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
          dynamicBullets: true,
        },
        onAny(eventName, ...args) {
          if ((typeof document !== 'undefined') && eventName.toString() == 'slideChangeTransitionStart') {
            const elementsVideo: HTMLCollectionOf<HTMLVideoElement> = document.getElementsByTagName('video');

            for (let i = 0; i < elementsVideo.length; i++) {
              if (elementsVideo[i].classList.contains('video-image-set')) {
                elementsVideo[i].pause();
              }
            }

            const elementsAudio: HTMLCollectionOf<HTMLAudioElement> = document.getElementsByTagName('audio');

            for (let i = 0; i < elementsAudio.length; i++) {
              if (elementsAudio[i].classList.contains('audio-image-set')) {
                elementsAudio[i].pause();
              }
            }
          }
        }
      }
    );
  }

  private subscribeToPopstate(): void {
    if (typeof window === 'undefined') return;
    if (this.popstateSubscription) return;

    this.popstateSubscription = fromEvent(window, 'popstate').subscribe(async (): Promise<void> => {
      await this.closeModal(true);
    });
  }

  private unsubscribeToPopstate(): void {
    if (this.popstateSubscription) {
      this.popstateSubscription.unsubscribe();
      this.popstateSubscription = null;
    }
  }

  // public reportFile(): void {
  //   this.reportService.openReportModal(ReportType.FILE, this.selectedFilesForSlider[this.swiper.activeIndex].id).then();
  // }
}
