import { Injectable } from '@angular/core';
import { StatusBar, StatusBarInfo } from '@capacitor/status-bar';
import { PlatformService } from './platform.service';

@Injectable({
  providedIn: 'root'
})
export class StatusBarService {
  private backgroundColor: string = '#000000';

  constructor(
    private platformService: PlatformService,
  ) {
  }

  public getInfo(): Promise<StatusBarInfo> {
    return StatusBar.getInfo();
  }

  public async setBackgroundColor(): Promise<void> {
    if (!this.platformService.isAndroidApp) return;

    await StatusBar.setBackgroundColor({color: this.backgroundColor});
  }

  public async activateOverlays(): Promise<void> {
    await this.setOverlays(true);
  }

  public async deactivateOverlays(): Promise<void> {
    await this.setOverlays(false);
    await this.setBackgroundColor();
  }

  private async setOverlays(activate: boolean): Promise<void> {
    if (!this.platformService.isAndroidApp) return;

    try {
      await StatusBar.setOverlaysWebView({overlay: activate});
    } catch (error) {
      console.error('Error setting status bar overlay:', error);
    }
  }
}
