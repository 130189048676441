<div>
  <ion-button (click)="present(template, $event)">
    <ion-icon slot="end" [name]="icon || 'ellipsis-vertical'"></ion-icon>
  </ion-button>
  <ng-template #template>
    <div (click)="popover?.dismiss()">
      <ng-content></ng-content>
    </div>
  </ng-template>
</div>
