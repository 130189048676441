<ion-header class="black title-center">
    <ion-toolbar>
        <ion-title>
            <h1>{{ 'pageNotFound' | translate }}</h1>
        </ion-title>
    </ion-toolbar>
</ion-header>
<ion-content class="not-found" [fullscreen]="true">
    <div class="not-found container"></div>
</ion-content>
