import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'timeAgo',
  pure: false // Нужно, чтобы pipe обновлялся при смене языка
})
export class TimeAgoPipe implements PipeTransform {
  constructor(
    private translate: TranslateService,
  ) {
  }

  transform(value: string | number | Date): string {
    if (!value) return '';

    const now: number = new Date().getTime();
    const date: number = new Date(value).getTime();
    const diffMs: number = now - date;

    const minutes: number = Math.floor(diffMs / 60_000);
    const hours: number = Math.floor(minutes / 60);
    const days: number = Math.floor(hours / 24);
    const weeks: number = Math.floor(days / 7);
    const months: number = Math.ceil(days / 30);
    const years: number = Math.ceil(days / 365);

    let key: string;
    let amount: number;

    if (minutes < 60) {
      key = 'minutes';
      amount = minutes;
    } else if (hours < 24) {
      key = 'hours';
      amount = hours;
    } else if (days < 7) {
      key = 'days';
      amount = days;
    } else if (weeks < 4) {
      key = 'weeks';
      amount = weeks;
    } else if (months < 12) {
      key = 'months';
      amount = months;
    } else {
      key = 'years';
      amount = years;
    }

    return this.translate.instant('pipes.timeAgo.' + key, {amount});
  }
}
