import { Injectable } from '@angular/core';
import { ConsoleErrorComponent } from './console-error.component';
import { ModalController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class ConsoleErrorService {
  private click: number = 0;

  constructor(
    private modalController: ModalController,
  ) {
  }

  public async openConsoleModal(): Promise<void> {
    this.click++;

    if (this.click === 5) {
      const consoleModal: HTMLIonModalElement = await this.modalController.create({
        component: ConsoleErrorComponent,
      });

      await consoleModal?.present();
    }

    setTimeout((): void => {
      this.click = 0;
    }, 6_000);
  }

  public async closeConsoleModal(): Promise<void> {
    await this.modalController?.dismiss();
  }
}