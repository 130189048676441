import { inject } from '@angular/core';
import { CanActivateFn } from '@angular/router';
import { AuthService } from './auth.service';
import { NavController } from '@ionic/angular';
import { SessionStorageService } from '../services/session-storage.service';


export const authCanActivate: CanActivateFn = async (route, state): Promise<boolean> => {
  const authService = inject(AuthService);
  const navController = inject(NavController);
  const sessionStorage = inject(SessionStorageService);

  if (!authService.isAuthenticated) {
    sessionStorage.setItem('backURL', state.url);
    await navController.navigateBack('/login');
    return false;
  }

  if (route.routeConfig?.path?.includes('registration') || route.routeConfig?.path?.includes('order-details')) {
    return true;
  }

  if (authService.isUserProfileIncomplete) {
    await navController.navigateBack('/registration', {replaceUrl: true});
    return false;
  }

  return true;
};