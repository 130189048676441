import { PickedFile } from '@capawesome/capacitor-file-picker';
import { ExtendedClient, } from '../api-clients/pyjam/extended-client';
import { ToastService } from './toast.service';
import { LoadingService } from './loading.service';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AddingFile } from '../ui-components/image-adding/image-adding.component';
import { HttpClient } from '@angular/common/http';
import {
  AddingFileWithDescription
} from '../ui-components/add-description-to-image/add-description-to-image.component';
import { BriefAddingFile } from '../task/task.controller';
import { MAX_FILE_SIZE, SIZE_LIMIT } from '../app.constants';
import { firstValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FileUploadingService {
  private EXCLUDED_EXTENSIONS: string[] = ['exe', 'bat', 'com', 'py', 'sh', 'php', 'jsp', 'asp', 'torrent', 'dll'];

  constructor(
    private extendedClient: ExtendedClient,
    private toastService: ToastService,
    private loadingService: LoadingService,
    private httpClient: HttpClient,
    private translate: TranslateService,
  ) {
  }

  public async pickedFilesToAddingFiles(pickedFiles: PickedFile[]): Promise<AddingFile[]> {
    const files: AddingFile[] = pickedFiles
      .filter((file: PickedFile): boolean => {
        if (file.name.lastIndexOf('.') > 0) {
          const fileExtension: string = file.name.substring(file.name.lastIndexOf('.') + 1, file.name.length);
          return !this.EXCLUDED_EXTENSIONS.includes(fileExtension);
        }
        return true;
      })
      .map((pickedFile: PickedFile) => {
        return {
          name: pickedFile.name,
          size: pickedFile.size,
          mimeType: pickedFile.mimeType,
          base64: `data:${pickedFile.mimeType};base64,${pickedFile.data}`,
          blob: pickedFile.blob,
          src: pickedFile.mimeType.includes('image') || pickedFile.mimeType.includes('video') ? `data:${pickedFile.mimeType};base64,${pickedFile.data}` : '',
        } as AddingFile;
      });

    if (files.length !== pickedFiles.length) {
      await this.toastService.warning(this.translate.instant('errors.forbiddenToUpload'));

      if (files.length < 1) {
        await this.loadingService.stop();
        return;
      }
    }

    const totalSize: number = files.reduce((totalSize: number, file: AddingFile) => totalSize + file.size, 0);

    if (totalSize >= SIZE_LIMIT.LARGE) {
      await this.toastService.warning(this.translate.instant('errors.files.maxTotalSize', {size: MAX_FILE_SIZE.LARGE}));
      await this.loadingService.stop();
      return;
    }
    return files;
  }

  public async profileAddFileToS3(file: AddingFileWithDescription): Promise<void> {
    // console.log('profileAddFileToS3!', file);
    const formFields = await firstValueFrom(this.extendedClient.profileFileAddFileBySignUrl(file.name, file.mimeType, file.description))
      .then((data) => {
        return data;
      })
      .catch((error) => {
        console.error(error);
        return error;
      });

    return await this.sendToC3(formFields, file, {'X-Amz-Meta-description': file.description});
  }

  public async taskAddFileToS3(taskId: number, file: BriefAddingFile): Promise<void> {
    const formFields = await firstValueFrom(this.extendedClient.taskAddFileBySignUrl(taskId, file.name, file.mimeType, file.question_id || undefined))
      .then((data) => {
        return data;
      })
      .catch((error) => {
        console.error(error);
        return error;
      });

    return await this.sendToC3(
      formFields,
      file,
      {
        'X-Amz-Meta-task-id': taskId,
        'X-Amz-Meta-question-id': file.question_id || ''
      }
    );
  }

  public async taskResultAddFileToS3(taskResultId: number, file: AddingFile): Promise<void> {
    // console.log('taskResultAddFileToS3!', file);
    const formFields = await firstValueFrom(this.extendedClient.taskResultAddFileBySignUrl(taskResultId, file.name, file.mimeType))
      .then((data) => {
        return data;
      })
      .catch((error) => {
        console.error(error);
        return error;
      });

    return await this.sendToC3(formFields, file, {'X-Amz-Meta-task-result-id': taskResultId});
  }

  public async draftAddFileToS3(draftId: number, questionId: number | undefined, file: AddingFile): Promise<void> {
    if (!file.base64 && !file.blob) {
      console.error('File has only URL and will not be sent to C3!');
      return;
    }
    // console.log('draftAddFileToS3!', file);
    const formFields = await firstValueFrom(this.extendedClient.draftAddFileBySignUrl(draftId, file.name, file.mimeType, questionId || undefined))
      .then((data) => {
        return data;
      })
      .catch((error) => {
        console.error(error);
        return error;
      });

    return await this.sendToC3(
      formFields,
      file,
      {
        'X-Amz-Meta-draft-id': draftId,
        'X-Amz-Meta-question-id': questionId || ''
      }
    );
  }

  public async chatAddFileToS3(chatId: number, file: AddingFile): Promise<void> {
    // console.log('chatAddFileToS3!', file);
    const formFields = await firstValueFrom(this.extendedClient.chatAddFileBySignUrl(chatId, file.name, file.mimeType))
      .then((data) => {
        // console.log('response:', data);
        return data;
      })
      .catch((error) => {
        console.error(error);
        return error;
      });

    return await this.sendToC3(formFields, file, {'X-Amz-Meta-chat-id': chatId});
  }

  public async userAddAvatarToS3(file: AddingFile): Promise<void> {
    // console.log('userAddAvatarToS3!', file);
    const formFields = await firstValueFrom(this.extendedClient.userAddAvatarBySignUrl(file.name, file.mimeType))
      .then((data) => {
        return data;
      })
      .catch((error) => {
        console.error(error);
        return error;
      });

    return await this.sendToC3(formFields, file);
  }

  public async extraWorkResultAddFileToS3(extraWorkId: number, file: AddingFile): Promise<void> {
    // console.log('extraWorkResultAddFileToS3!', file);
    let formFields = await this.extendedClient.extraWorkAddFileBySignUrl(extraWorkId, file.name, file.mimeType).toPromise()
      .then((data) => {
        return data;
      }).catch((err) => {
        console.error(err);
        return err;
      });

    return await this.sendToC3(formFields, file, {'X-Amz-Meta-extra-work-id': extraWorkId});
  }

  private async sendToC3(formFields: HTMLFormElement, file: AddingFile, additionalFields = {}): Promise<void> {
    const formData: any = new FormData();

    if (!file.blob) {
      if (!file.base64) {
        console.error('No base64 file data!');
        return;
      }
      file.blob = this.base64ToBlob(file.base64);
    }

    formData.append('key', formFields.key);
    formData.append('Content-Type', formFields.contentType);
    formData.append('X-Amz-Credential', formFields.xAmzCredential);
    formData.append('X-Amz-Algorithm', formFields.xAmzAlgorithm);
    formData.append('X-Amz-Date', formFields.xAmzDate);
    formData.append('Policy', formFields.policy);
    formData.append('X-Amz-Signature', formFields.xAmzSignature);
    formData.append('X-Amz-Meta-object-type', formFields.xAmzMetaObjectType);

    Object.keys(additionalFields).forEach((field: string): void => {
      formData.append(field, additionalFields[field]);
    });

    formData.append('file', file.blob);

    return await firstValueFrom(this.httpClient.post(formFields.action, formData))
      .then((): void => {
        // console.log('File successfully uploaded');
      })
      .catch((error) => {
        console.error(error);
        return error;
      });
  }

  private base64ToBlob(dataURI: string): Blob {
    const byteString: string = atob(dataURI.split(',')[1]);
    // const mimeString: string = dataURI.split(',')[0].split(':')[1].split(';')[0];

    // write the bytes of the string to an ArrayBuffer
    const ab: ArrayBuffer = new ArrayBuffer(byteString.length);
    const ia: Uint8Array = new Uint8Array(ab);

    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    // write the ArrayBuffer to a blob, and you're done
    return new Blob([ab]);
  }
}
