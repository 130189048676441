import { AfterViewInit, Component, OnDestroy } from '@angular/core';
import { ConsoleErrorService } from './console-error.service';
import { SubscriptionsBag } from '../services/subscriptions-bag';
import { StoreErrorHandlerService } from '../services/error-handlers/store-error-handler.service';
import { NgIf } from '@angular/common';
import { IonicModule } from '@ionic/angular';

@Component({
  selector: 'console-error',
  templateUrl: './console-error.component.html',
  styleUrls: ['./console-error.component.scss'],
  standalone: true,
  imports: [
    NgIf,
    IonicModule
  ]
})
export class ConsoleErrorComponent implements AfterViewInit, OnDestroy {
  private sb: SubscriptionsBag = new SubscriptionsBag();

  constructor(
    public storeErrorHandlerService: StoreErrorHandlerService,
    private consoleErrorService: ConsoleErrorService,
  ) {
  }

  ngAfterViewInit() {
    for (let i = 0; i < this.storeErrorHandlerService.errors.length; i++) {
      this.renderError(this.storeErrorHandlerService.errors[i]);
    }

    this.sb.sub = this.storeErrorHandlerService.errorSubject.pipe(
    ).subscribe((error: any): void => {
      this.renderError(error);
    });
  }

  ngOnDestroy() {
    this.sb.unsubscribeAll();
  }

  public async closeConsoleModal(): Promise<void> {
    await this.consoleErrorService.closeConsoleModal();
  }

  public clearConsoleModal(): void {
    this.storeErrorHandlerService.clearErrors();

    if (typeof document === 'undefined') return;

    const errorsListEl: Element = document.querySelector('.errors-list');
    const newErrorsListEl: Node = errorsListEl.cloneNode(false);
    errorsListEl.parentNode.replaceChild(newErrorsListEl, errorsListEl);
  }

  private renderError(error: string): void {
    if (!error || typeof document === 'undefined') return;

    const errorHandlerEl: Element = document.querySelector('.errors-list');
    if (!errorHandlerEl) return;

    const errorItemEl: HTMLPreElement = document.createElement('pre');

    const date: Date = new Date();
    const time: string = `${date.getHours()}:${date.getMinutes()}:${date.getSeconds().toString().padStart(2, '0')}`;

    errorItemEl.innerText = time + ' | ' + error;

    errorItemEl.style.padding = '8px';
    errorItemEl.style.margin = '8px';
    errorItemEl.style.background = 'rgb(74 54 53)';
    errorItemEl.style.borderRadius = '15px';

    errorHandlerEl.appendChild(errorItemEl);
  }
}
