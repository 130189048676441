import { Injectable } from "@angular/core";
import { Meta, Title } from "@angular/platform-browser";

@Injectable({
  providedIn: 'root'
})
export class MetaTagService {
  constructor(private title: Title, private meta: Meta) {}
   
  updateTitle(title: string) {
    this.title.setTitle(title);
  }

  updateDescription(desc: string) {
    this.meta.updateTag({ name: 'description', content: desc });
    this.meta.updateTag({ name: 'og:description', content: desc });
  }
 
  updateVideoSocialInfo(videoUrl: string, title: string, desc: string, previewUrl?: string) {
    this.meta.updateTag({ name: 'og:type', content: 'video' });
    this.meta.updateTag({ name: 'og:title', content: title });
    this.meta.updateTag({ name: 'og:description', content: desc });
    this.meta.updateTag({ name: 'og:video', content: videoUrl });
    if (previewUrl) {
      this.meta.updateTag({ name: 'og:image', content: previewUrl });
    }
  }
  
  updateKeywords(key: string) {
    this.meta.updateTag({ name: 'keywords', content: key });
  }
}