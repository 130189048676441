<ion-content >
  <ion-img [src]="captchaSrc" alt="captcha"></ion-img>

  <ion-input #captcha
             [clearInput]="true"
             [autofocus]="true"
             [placeholder]="'xxxxx'"
             [minlength]="5"
             [maxlength]="5"
             inputmode="text"
             type="text"
             name="captcha"
             required>
  </ion-input>

  <ion-button class="button-bottom" color="dark" expand="block"
              (click)="sendCaptcha(captcha)"
              [disabled]="captcha.value.toString().length !== 5">
    {{ 'registration.sendCaptcha' | translate }}
  </ion-button>
</ion-content>
