import { Component } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { IonicModule } from '@ionic/angular';
import { Meta } from '@angular/platform-browser';

@Component({
  selector: 'not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.scss'],
  standalone: true,
  imports: [
    IonicModule,
    TranslateModule
  ]
})
export class NotFoundComponent {

    constructor(
        private metaService: Meta
    ) {
        this.metaService.updateTag({ name: 'robots', content: 'noindex, nofollow' });
    }
}
