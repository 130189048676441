import { Injectable } from '@angular/core';
import { GetResult, Preferences } from '@capacitor/preferences';
import DeviceProtectedSharedPreferences
  from '../plugins/deviceProtectedSharedPreferences/deviceProtectedSharedPreferences.plugin';
import { PlatformService } from '../services/platform.service';
import { LocalStorageService } from '../services/local-storage.service';

@Injectable({
  providedIn: 'root',
})
export class TokenStoreService {
  private readonly ACCESS: string = 'access_token';
  private readonly REFRESH: string = 'refresh_token';
  private readonly secured: boolean;
  private readonly tempTokenLength: number = 16;

  constructor(
    private platformService: PlatformService,
    private localStorage: LocalStorageService
  ) {
    this.secured = this.platformService.isDevice && this.platformService.isAndroid;
  }

  public async getToken(): Promise<string | null> {
    try {
      const accessTokenResult: GetResult = this.secured
        ? await DeviceProtectedSharedPreferences.get({key: this.ACCESS})
        : (typeof window !== 'undefined') ? await Preferences.get({key: this.ACCESS}) : null;

      return accessTokenResult?.value ?? null;
    } catch (error) {
      console.error('getToken error:', error);
      return null;
    }
  }

  public async getRefreshToken(): Promise<string | null> {
    try {
      const refreshTokenResult: GetResult = this.secured
        ? await DeviceProtectedSharedPreferences.get({key: this.REFRESH})
        : (typeof window !== 'undefined') ? await Preferences.get({key: this.REFRESH}) : null;

      return refreshTokenResult?.value ?? null;
    } catch (error) {
      console.error('getRefreshToken error:', error);
      return null;
    }
  }

  public async setTokens(accessToken: string, refreshToken: string): Promise<void> {
    try {
      this.secured
        ? await DeviceProtectedSharedPreferences.set({key: this.ACCESS, value: accessToken})
        : await Preferences.set({key: this.ACCESS, value: accessToken});

      this.secured
        ? await DeviceProtectedSharedPreferences.set({key: this.REFRESH, value: refreshToken})
        : await Preferences.set({key: this.REFRESH, value: refreshToken});
    } catch (error) {
      console.error('setTokens error:', error);
    }
  }

  public async removeTokens(): Promise<void> {
    try {
      this.secured
        ? await DeviceProtectedSharedPreferences.remove({key: this.ACCESS})
        : await Preferences.remove({key: this.ACCESS});

      this.secured
        ? await DeviceProtectedSharedPreferences.remove({key: this.REFRESH})
        : await Preferences.remove({key: this.REFRESH});
    } catch (error) {
      console.error('removeTokens error:', error);
    }
  }

  public getTempToken(): string {
    if (this.platformService.isSSR) return 'bot';

    let tempToken: string = this.localStorage.getItem('tempToken');

    if (!tempToken && !this.platformService.isSSR) {
      tempToken = this.generateTempToken(this.tempTokenLength);
      this.localStorage.setItem('tempToken', tempToken);
    }
    return tempToken;
  }

  private generateTempToken(length: number): string {
    const charset = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let tempToken: string = '';
    const randomValues = new Uint8Array(length);
    window.crypto.getRandomValues(randomValues);

    for (let i = 0; i < length; i++) {
      tempToken += charset.charAt(randomValues[i] % charset.length);
    }
    return tempToken;
  }
}
