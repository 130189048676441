import { Component, Input } from '@angular/core';
import { IonicModule, IonInput, ModalController } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'captcha-modal',
  templateUrl: './captcha-modal.component.html',
  styleUrls: ['./captcha-modal.component.scss'],
  imports: [
    IonicModule,
    TranslateModule
  ],
  standalone: true
})
export class CaptchaModalComponent {
  @Input() captchaSrc: string | undefined = undefined; // base64

  constructor(
    private modalController: ModalController,
  ) {
  }

  public async sendCaptcha(input: IonInput): Promise<void> {
    await this.modalController?.dismiss(input.value);
  }
}
