<ion-modal #imageSliderModal>
  <ng-template>
    <ion-header class="black">
      <ion-toolbar>
        <div class="title-slot">
          <div class="title">
            <ng-container *ngIf="parsedUser">
              <div class="avatar">
                <app-avatar
                  class="ion-margin-end" slot="start"
                  style="width: 40px; height: 40px"
                  [showOriginalByClick]="false"
                  [name]="parsedUser?.name + ' ' + parsedUser?.surname"
                  typeAvatar='with-light'
                  sizeLight="super-big"
                  [avatarFile]="parsedUser?.avatarFile">
                </app-avatar>
              </div>
              <div class="info">
                <div>
                  <h3>{{ parsedUser?.name }} {{ parsedUser?.surname }}</h3>
                </div>
              </div>
            </ng-container>
          </div>
          <ion-buttons>
            <ion-button *ngIf="allowDownload" (click)="downloadFile()">
              <ion-icon class="menu" name="download-outline"></ion-icon>
            </ion-button>
            <ion-button (click)="openMenu()">
              <ion-icon class="menu" name="ellipsis-horizontal"></ion-icon>
            </ion-button>
            <ion-button (click)="closeModal()">
              <ion-icon class="menu" name="close"></ion-icon>
            </ion-button>
          </ion-buttons>
        </div>
      </ion-toolbar>
    </ion-header>

    <ion-content class="ion-padding-bottom ion-padding-end ion-padding-start">
      <div class="images-slider" *ngIf="selectedFilesForSlider?.length" (click)="closeModal()">
        <div class="slider">
          <div class="image-wiper">
            <div class="swiper-wrapper">
              <div class="swiper-slide" *ngFor="let file of selectedFilesForSlider; let i = index">
                <div class="content swiper-zoom-container">
                  <div [ngSwitch]="file.type.id">
                    <img *ngSwitchCase="FileTypes.TYPE_IMAGE" class="file-img"
                         (click)="$event.stopImmediatePropagation();"
                         src="{{ file.resizes ? file.resizes[2].url : file.url }}"/>

                    <video *ngSwitchCase="FileTypes.TYPE_VIDEO" class="video-image-set"
                           (click)="$event.stopImmediatePropagation();"
                           [autoplay]="false"
                           [loop]="false"
                           controls>
                      <source src="{{file.url}}">
                    </video>

                    <div *ngSwitchCase="FileTypes.TYPE_AUDIO" class="audio">
                      <audio class="audio-image-set"
                             (click)="$event.stopImmediatePropagation();"
                             src="{{file.url}}"
                             [autoplay]="false"
                             [loop]="false"
                             controls>
                      </audio>
                      <div class="info">
                        <div class="name">{{ file.name }}</div>
                        <div class="size">{{ file.size | sizeFile }}</div>
                      </div>
                    </div>

                    <div *ngSwitchDefault class="file" (click)="$event.stopImmediatePropagation();">
                      <div class="icon">
                        <img class="icon-file" src="{{file.name | iconForFile}}" alt="">
                      </div>
                      <div class="info">
                        <div class="name">{{ file.name }}</div>
                        <div class="size">{{ file.size | sizeFile }}</div>
                      </div>
                    </div>

                    <div class="description" *ngIf="file.description">
                      <div>{{ file.description }}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ion-content>
  </ng-template>
</ion-modal>

