import { Injectable } from '@angular/core';
import { PlatformService } from './platform.service';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {

  public get isAvailable(): boolean {
    return typeof localStorage !== 'undefined';
  }

  public get introPassed(): boolean {
    if (this.platformService.isSSR) return true;

    return this.getItem('introPassed');
  }

  public get tutorialControlPassed(): boolean {
    if (this.platformService.isSSR) return true;

    return this.getItem('tutorialControlPassed');
  }

  constructor(
    private platformService: PlatformService,
  ) {
  }

  public setItem(key: string, value: any): void {
    if (!this.isAvailable) return;

    const stringValue: string = (typeof value !== 'string') ? JSON.stringify(value) : value;
    localStorage.setItem(key, stringValue);
  }

  public getItem(key: string, parse = false): any | null {
    if (!this.isAvailable) return null;

    const item: string | null = localStorage.getItem(key);

    return (item && parse) ? JSON.parse(item) : item;
  }

  public removeItem(key: string): void {
    if (!this.isAvailable) return;

    localStorage.removeItem(key);
  }
}
