import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SessionStorageService {

  public get isAvailable(): boolean {
    return typeof sessionStorage !== 'undefined';
  }

  public setItem(key: string, value: any): void {
    if (!this.isAvailable) return;

    const stringValue: string = (typeof value !== 'string') ? JSON.stringify(value) : value;
    sessionStorage.setItem(key, stringValue);
  }

  public getItem(key: string, parse = false): any | null {
    if (!this.isAvailable) return null;

    const item: string | null = sessionStorage.getItem(key);
    return (item && parse) ? JSON.parse(item) : item;
  }

  public removeItem(key: string): void {
    if (!this.isAvailable) return;

    sessionStorage.removeItem(key);
  }
}
