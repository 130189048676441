import { Injectable } from '@angular/core';
import { ToastController, ToastOptions } from '@ionic/angular';

type ToastType = 'success' | 'info' | 'warning' | 'error';

@Injectable({providedIn: 'root'})
export class ToastService {
  private toastConfig = {
    success: {color: 'success', duration: 3_000},
    info: {color: 'primary', duration: 5_000},
    warning: {color: 'warning', duration: 5_000},
    error: {color: 'danger', duration: 8_000},
  };

  constructor(
    private toastController: ToastController,
  ) {
  }

  async success(message: string, options?: ToastOptions): Promise<void> {
    return this.showToastByType('success', message, options);
  }

  async info(message: string, options?: ToastOptions): Promise<void> {
    return this.showToastByType('info', message, options);
  }

  async warning(message: string, options?: ToastOptions): Promise<void> {
    return this.showToastByType('warning', message, options);
  }

  async error(message: string, options?: ToastOptions): Promise<void> {
    return this.showToastByType('error', message, options);
  }

  async dismissById(toastId: string): Promise<void> {
    try {
      await this.toastController?.dismiss(undefined, undefined, toastId);
    } catch (error) {
      console.log(error);
    }
  }

  async dismissLatest(): Promise<void> {
    try {
      const toast: HTMLIonToastElement = await this.toastController.getTop();
      await toast?.dismiss();
    } catch (error) {
      console.log(error);
    }
  }

  async dismissAll(): Promise<void> {
    let toast: HTMLIonToastElement = await this.toastController.getTop();

    while (toast) {
      await toast?.dismiss();
      toast = await this.toastController.getTop();
    }
  }

  private async showToastByType(type: ToastType, message: string, customOptions?: ToastOptions): Promise<void> {
    if (typeof window === 'undefined') return;

    try {
      const {color, duration} = this.toastConfig[type];
      const typeOptions: ToastOptions = {color, duration};

      const toast: HTMLIonToastElement = await this.toastController.create({
        ...typeOptions,
        message,
        ...customOptions,
      });

      await toast?.present();

      toast.onclick = async (): Promise<void> => {
        await toast?.dismiss();
      };
    } catch (error) {
      console.log(error);
    }
  }
}
