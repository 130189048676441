import { Injectable, NgZone } from '@angular/core';
import { Config } from '@ionic/angular';
import { NavigationService } from './navigation.service';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { ActivatedRouteFixService } from './activated-route-fix.service';
import { SessionStorageService } from './session-storage.service';

@Injectable({
  providedIn: 'root'
})
export class BackButtonService {

  constructor(
    private config: Config,
    private navigationService: NavigationService,
    private sessionStorage: SessionStorageService,
    private activatedRoute: ActivatedRoute,
    private activatedRouteFix: ActivatedRouteFixService,
    private router: Router,
    private ngZone: NgZone,
  ) {
    this.registerBackButtonAction();
  }

  registerBackButtonAction() {
    if (typeof document !== 'undefined') {
      document.addEventListener('ionBackButton', (event: Event): void => {
        this.ngZone.run((): void => {
          this.goBack(null);
        });
      }, false);
    }
  }

  public goBack(defHref: string, queryParams: Params = null): void {
    const replaceUrl: boolean = true;
    const prevUrl: string = this.router.url;
    if (defHref && defHref.indexOf('?') !== -1) {
      const searchParams = defHref.substring(defHref.indexOf('?'));
      queryParams = queryParams || Object.fromEntries(new URLSearchParams(searchParams).entries());
      defHref = defHref.substring(0, defHref.indexOf('?'));
    }
    const defaultHref: string = defHref || this.config.get('backButtonDefaultHref');
    const relativeTo: ActivatedRoute = ((defaultHref?.length) && (defaultHref[0] == '/')) ? null : this.activatedRouteFix.getActivatedRoute(this.activatedRoute);

    this.navigationService.goBack(
      replaceUrl,
      defaultHref,
      relativeTo,
      queryParams
    ).then(() => {
      setTimeout((): void => {
        if (this.router.url == prevUrl) { // navigation cannot go back further
          this.router.navigateByUrl('/avatar').then();
          // TODO: minimize application
        }
      }, 100);
    }).catch((error: any) => {
      console.error('Go back error:', error);
    });
  }
}
