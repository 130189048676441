import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SignInComponent } from './sign-in/sign-in.component';
import { SignInConfirmComponent } from './sign-in-confirm/sign-in-confirm.component';
import { signInCanActivate } from './sign-in/sign-in.guard';
// import { WelcomeComponent } from './welcome/welcome.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'sign-in',
    pathMatch: 'full',
    data: {title: 'seo.title.login', description: 'seo.description.login'}
  },
  {
    path: 'sign-in',
    component: SignInComponent,
    canActivate: [signInCanActivate],
    data: {showMenu: false, title: 'seo.title.login', description: 'seo.description.login'}
  },
  {
    path: 'sign-in-confirm',
    component: SignInConfirmComponent,
    canActivate: [signInCanActivate],
    data: {showMenu: false, title: 'seo.title.login', description: 'seo.description.login'}
  },
  // {
  //   path: 'welcome',
  //   component: WelcomeComponent,
  //   data: {showMenu: false}
  // },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class LoginRoutingModule {
}
