import { Component } from '@angular/core';
import { OnlineStateService } from '../../services/online-state.service';
import { AsyncPipe, NgIf } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'offline-toast',
  templateUrl: './offline-toast.component.html',
  styleUrls: ['./offline-toast.component.scss'],
  standalone: true,
  imports: [
    NgIf,
    AsyncPipe,
    TranslateModule,
  ]
})
export class OfflineToastComponent {

  constructor(
    public onlineStateService: OnlineStateService,
  ) {
  }
}
