import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { SessionStorageService } from '../../services/session-storage.service';

@Component({
  selector: 'agreements-checkbox',
  templateUrl: './agreements-checkbox.component.html',
  styleUrls: ['./agreements-checkbox.component.scss'],
})
export class AgreementsCheckboxComponent {
  @Input() loginForm: FormGroup;
  @Input() controlName: string;
  @Output() agreementChecked: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(private sessionStorage: SessionStorageService) {
    this.sessionStorage.setItem('lastURL', '/login');
  }

  agreementCheckboxChange(event: any): void {
    this.agreementChecked.emit(event.detail.checked);
  }
}
