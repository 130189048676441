import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, fromEvent, Observable, Subject } from 'rxjs';
import { distinctUntilChanged, filter, takeUntil } from 'rxjs/operators';
import { AppStateChangeService } from './app-state-change.service';

@Injectable({
  providedIn: 'root'
})
export class OnlineStateService implements OnDestroy {
  private isOfflineSubject: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public isOffline$: Observable<boolean> = this.isOfflineSubject.asObservable();
  private destroySubject: Subject<void> = new Subject<void>();

  constructor(
    private appStateChangeService: AppStateChangeService,
  ) {
    this.subscribeToWindowEvents();
    this.subscribeToAppState();
  }

  ngOnDestroy() {
    this.destroySubject.next();
    this.destroySubject.complete();
  }

  get isOffline(): boolean {
    return this.isOfflineSubject.value;
  }

  private subscribeToWindowEvents(): void {
    if (typeof window === 'undefined') return;

    fromEvent(window, 'online').pipe(
      takeUntil(this.destroySubject),
    ).subscribe((): void => {
      console.log('\x1b[32m' + 'You are online' + '\x1b[0m');
      this.isOfflineSubject.next(false);
    });

    fromEvent(window, 'offline').pipe(
      takeUntil(this.destroySubject),
    ).subscribe((): void => {
      console.log('\x1b[31m' + 'You are offline!' + '\x1b[0m');
      this.isOfflineSubject.next(true);
    });
  }

  private subscribeToAppState(): void {
    this.appStateChangeService.appStateChange$.pipe(
      filter((isActive: boolean): boolean => isActive),
      distinctUntilChanged(),
      takeUntil(this.destroySubject),
    ).subscribe((): void => {
      if (typeof navigator !== 'undefined') {
        console.log((navigator.onLine) ? '\x1b[32m' + 'You are online' + '\x1b[0m' : '\x1b[31m' + 'You are offline' + '\x1b[0m');
        this.isOfflineSubject.next(!navigator.onLine);
      } else {
        this.isOfflineSubject.next(false);
      }
    });
  }
}
