import { Injectable, NgZone } from '@angular/core';
import { NavController } from '@ionic/angular';
import { ActivatedRoute, Params } from '@angular/router';
import { SelectedTab, TaskVm } from '../task/task.controller';
import { NavigationOptions } from '@ionic/angular/providers/nav-controller';
import { SessionStorageService } from './session-storage.service';

@Injectable({
  providedIn: 'root'
})
export class NavigationService {

  public constructor(
    private navController: NavController,
    private ngZone: NgZone,
    private sessionStorage: SessionStorageService
  ) {
  }

  public async goBack(replaceUrl: boolean = false,
                      defaultHref: string = null,
                      relativeTo: ActivatedRoute = null,
                      queryParams: Params = null): Promise<void> {
    const canGoBack: boolean = ((typeof window !== 'undefined') && window.history.length > 2 && !defaultHref);

    if (canGoBack) {
      this.navController.back();
    } else {
      // TODO: minimize application
      defaultHref = defaultHref || this.sessionStorage.getItem('lastURL') || '/avatar';

      const options: NavigationOptions = {
        animated: true,
        replaceUrl,
        relativeTo,
        queryParams,
      };

      await this.ngZone.run(async (): Promise<void> => {
        await this.navController.navigateBack([defaultHref], options);
      });
    }
  }

  public goBackWithoutAnimation(): void {
    this.navController.back({animated: false});
  }

  public getUrl(taskVm: TaskVm,
                selectedTab: SelectedTab,
                activatedRoute: ActivatedRoute): string {
    if (selectedTab === SelectedTab.People) {
      return activatedRoute.snapshot.routeConfig.path.split('/').length === 1 ? '../people' : '../../people';
    }
    if (selectedTab === SelectedTab.Order) {
      if (!taskVm.category_id) {
        return '../category';
      } else if (!taskVm.name) {
        return '../name';
      } else if (!!taskVm.nextQuestionId) {
        return '../question/' + taskVm.nextQuestionId;
      } else if (!taskVm.deadline && (!taskVm.deadline_at || taskVm.deadline_at == '')) {
        return '../deadline';
      } else if (!taskVm.price) {
        return '../price';
      } else if (!taskVm.description || taskVm.description == '') {
        return '../description';
      }
    }
  }
}
