<div class="files" *ngIf="files.length">
  <div class="file" *ngFor="let file of files, let i = index" (click)="imageSlider.showImages(files, i, parsedUser)">
    <div *ngIf="file.type && file.type?.name?.includes('image')" class="file-image">
      <img [src]="file.resizes[1]?.url" alt="" (load)="checkLoad()" (error)="checkLoad()">
    </div>

    <div *ngIf="file.type?.name === 'video'" class="file-video">
      <video id="video_background-{{file.id}}" (canplay)="checkLoad()">
        <source [src]='file?.url + "#t=0.001"' type="video/mp4">
      </video>
    </div>

    <div *ngIf="file.type && !file.type?.name.includes('image')" class="file-doc" appNoContextMenu>
      <div class="icon">
        <img class="icon-file" src="{{file.name | iconForFile}}" alt="" (load)="checkLoad()" (error)="checkLoad()">
      </div>
      <div class="info">
        <div class="name">{{ file.name }}</div>
        <div class="size" *ngIf="showFileSize">{{ file.size | sizeFile }}</div>
      </div>
    </div>

    <div *ngIf="!file?.type && !file?.name" class="file-image">
      <img src="assets/images/undelivered_file.svg" alt="" (load)="checkLoad()" (error)="checkLoad()"/>
    </div>
  </div>
</div>

<app-image-slider #imageSlider
                  [allowDownload]="allowDownload"
                  [allowEditing]="allowEditing"
                  [allowDeleting]="allowDeleting"
                  (deleteFileEvent)="deleteProfileFile($event)">
</app-image-slider>
