import { Provider } from '@angular/core';
import { HttpBackend, HttpClient } from '@angular/common/http';
import { environment } from '../environments/environment';
import { MissingTranslationHandler, MissingTranslationHandlerParams, TranslateLoader } from '@ngx-translate/core';
import merge from 'deepmerge';
import { forkJoin, Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

function createTranslateLoader(httpHandler: HttpBackend): MyMultiTranslateHttpLoader {
  if (typeof window === 'undefined') {
    const baseUrl: string = environment.urlBackEnd; // environment.urlApp
    const coreUrl: string = baseUrl + '/assets/i18n/core/';
    const extraUrl: string = baseUrl + '/assets/i18n/extra/';
    console.log('URLs to translations:', coreUrl, extraUrl);
    return new MyMultiTranslateHttpLoader(httpHandler, [coreUrl, extraUrl], false);
  } else {
    return new MyMultiTranslateHttpLoader(httpHandler, ['/assets/i18n/core/', '/assets/i18n/extra/'], false);
  }
}

class MyMultiTranslateHttpLoader implements TranslateLoader {
  private readonly _handler: HttpBackend;
  private readonly _resourcesPrefix: string[];
  private readonly _useBrowserCache: boolean;

  constructor(handler: HttpBackend, resourcesPrefix: string[], useBrowserCache: boolean) {
    this._handler = handler;
    this._resourcesPrefix = resourcesPrefix;
    this._useBrowserCache = useBrowserCache;
  }

  getTranslation(lang: string): Observable<any> {
    const requests = this._resourcesPrefix.map((prefix) => {
      const suffix: string = (this._useBrowserCache) ? '' : `?v=${new Date().getTime()}`;
      const path: string = `${prefix}${lang}.json` + suffix;

      return new HttpClient(this._handler).get(path).pipe(
        catchError((res: any) => {
          console.error('Something went wrong for the following translation file:', path);
          console.error(res.message);
          return of({});
        })
      );
    });

    return forkJoin(requests).pipe(map((response: any) => merge.all(response)));
  }
}

export class MyMissingTranslationHandler implements MissingTranslationHandler {
  handle(params: MissingTranslationHandlerParams): string {
    console.error(`Translation missing for key:'${params.key}'`);
    return params.key;
  }
}

export const TranslateLoaderProvider: Provider = {
  provide: TranslateLoader,
  useFactory: (createTranslateLoader),
  deps: [HttpBackend]
};
