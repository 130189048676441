export const environment = {
  production: true,
  isInitSentry: true,
  useSmartlook: true,
  smartlookApiKey: 'ec03a6d5de192cfd343c764d87b528dac6165322',
  useYm: false,
  ymId: '93158092',
  useFbSdk: false,
  fbAppId: '6470088146393673',
  fbTrId: '1116042569361867',
  urlMediaServer: 'https://media.pyjam.com',
  urlApp: 'https://app.pyjam.com',
  categoryApiUrl: 'https://nbr.ru/tgbot/tg.php?pyjam=1',
  urlBackEnd: 'https://app.pyjam.com',
  urlBackEndApi: 'https://app.pyjam.com/api',
  socketConfig: {url: 'https://app.pyjam.com', options: {autoConnect: false}},
  googleMapApiKey: 'AIzaSyD0UHMSVCEaPbqMHBNbxVVgzC0WDj8lPr8',
  peerServerConfig: {
    host: 'dev.pyjam.com',
    port: 4200,
    path: '/myapp',
    config: {
      iceServers: [
        // {
        // urls: ['stun:stun.l.google.com:19302'],
        // },
        {
          urls: ['stun:app.pyjam.com:3478', 'turn:app.pyjam.com:3478'/*, 'stun:dev.pyjam.com:3478'*/],
          username: 'user',
          credential: '234567Qwe'
        }
      ],
      iceTransportPolicy: 'all' /* 'relay' */,
      // rtcpMuxPolicy: 'require'
    }
  },
  defaultLanguage: 'en',
  activeLanguages: ['en', 'pl'],
  firebase: {
    apiKey: 'AIzaSyBDD-zWmRLDwuYxQc5YIMqoHDAACdVCcDI',
    authDomain: 'pyjam-1bcb0.firebaseapp.com',
    projectId: 'pyjam-1bcb0',
    storageBucket: 'pyjam-1bcb0.appspot.com',
    messagingSenderId: '715986336885',
    appId: '1:715986336885:web:efa96afb2ef6da75e0cc3c',
    measurementId: 'G-9E7B9XRZBE'
  },
  google: {
    clientId: '715986336885-t92um245866a2p6d0f6kner78voktrrg.apps.googleusercontent.com',
    scopes: ['profile', 'email'],
  },
  apple: {
    clientId: 'com.devpyjam.pyjam',
    redirectURI: 'https://app.pyjam.com/api/auth/webhook',
    scopes: 'email name',
  },
  paypal: {
    clientId: 'AR3be0Vt1vTyKiiSVvWDJHXz2sQ5jZQtW2Pd3Hyk92brTl2oFD3BI67LjJ4AlvbJ4EPPsyVkEHK2fGM3',
  },
  stripe: {
    key: {
      test: 'pk_test_51LUWeIHbIgTBN9kjYigfRG5xj48exoC0vH7LIp1M485GiPDhINHyyLV2nDxJ0aJSDuDkuAaSEMQaJjBsP1oHGLmx00R8ojv26P',
      live: 'pk_live_51LUWeIHbIgTBN9kjjXXpqmMcuorA3cMgjUmOFDpn5VJrOPX2zW6MQG77M4EcpHmw7pXOMltilZP9mYnSIzQo1ALn00LkiJyGaK',
    }
  },
  highMediaQuality: true,
};
